import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function NoAuthRoute({ component: Component, ...rest }) {
  const authToken = localStorage.getItem('accessToken');
  return (
    <Route
      {...rest}
      render={props =>
        !authToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/home', state: { referer: props.location } }} />
        )
      }
    />
  );
}

export default NoAuthRoute;
