import client from '.';

// AcceptanceCriteria Register

export const getAcceptanceCriteriaChartAPI = params => {
  return client.get('/AcceptanceCriteriaRegister/get-acceptance-criteria-chart', { params: params });
};

export const assignAcceptanceToFolderAPI = params => {
  return client.post('/AcceptanceCriteriaRegister/assign-acceptance-to-folder', params);
};

export const reparentAcceptanceCriteriaAPI = params => {
  return client.post('/AcceptanceCriteriaRegister/reparent-acceptance-criteria', params);
};
// end AcceptanceCriteria Register

// =============== Acceptance Criteria Detail ==================== //
export const getAcceptanceCriteriaDetailAPI = (id: number) => {
  return client
    .get(`AcceptanceCriteria/get-acceptance-criteria-detail?acceptanceCriteriaId=${id}`)
    .then(res => res.data.data);
};

export const getAcceptanceCriteriaSettingAPI = () => {
  return client.get(`AcceptanceCriteria/get-acceptance-criteria-setting`).then(res => res.data);
};

export const createAcceptanceCriteriaAPI = (param: any) => {
  return client.post(`AcceptanceCriteria/create-acceptance-criteria`, param).then(res => res.data);
};

export const updateAcceptanceCriteriaAPI = (param: any) => {
  return client.post(`AcceptanceCriteria/update-acceptance-criteria`, param).then(res => res.data);
};

export const deleteAcceptanceCriteriaAPI = (param: any) => {
  return client.delete(`AcceptanceCriteria/delete-acceptance-criteria`, { data: param }).then(res => res.data);
};

export const getAcceptanceCriteriaHistoryAPI = params => {
  return client.get('/AcceptanceCriteria/get-acceptance-criteria-history', { params: params });
};

export const acceptanceDraftAPI = (id: any) => {
  return client.post(`AcceptanceCriteria/acceptance-draft?acceptanceCriteriaId=${id}`).then(res => res.data);
};

export const acceptanceReadyForReviewAPI = (id: any) => {
  return client.post(`AcceptanceCriteria/acceptance-ready-for-review?acceptanceCriteriaId=${id}`).then(res => res.data);
};

export const acceptanceReworkRequiredAPI = (id: any) => {
  return client.post(`AcceptanceCriteria/acceptance-rework-required?acceptanceCriteriaId=${id}`).then(res => res.data);
};

export const acceptanceApprovedAPI = (id: any) => {
  return client.post(`AcceptanceCriteria/acceptance-approved?acceptanceCriteriaId=${id}`).then(res => res.data);
};

export const acceptanceWorkflowAPI = (param: any, title: string) => {
  return client.post(`AcceptanceCriteria/acceptance-${title}`, param).then(res => res.data);
};

export const getLinkedArticleAPI = (acceptanceId: number, selectionType: number, searchString: string) => {
  return client
    .get(
      `Acceptance/get-acceptance-article-scope?acceptanceId=${acceptanceId}&selectionType=${selectionType}&searchString=${searchString}`,
    )
    .then(res => res.data);
};

export const assignArticleAcceptanceAPI = params => {
  return client.post(`Acceptance/assign-article-to-acceptance`, params).then(res => res.data);
};

export const removeArticleAcceptanceAPI = params => {
  return client.post(`Acceptance/remove-acceptance-article-scope`, params).then(res => res.data);
};

export const getAcceptanceIssueStatusAPI = (id: number) => {
  return client.get(`AcceptanceCriteria/get-acceptance-issue-status?acceptanceCriteriaId=${id}`).then(res => res.data);
};
// feature file

export const getFeatureFileAPI = params => {
  return client.get('/AcceptanceCriteriaFeatureFile/get-feature-file', { params: params });
};

export const getActionWordAPI = () => {
  return client.get('/AcceptanceCriteriaFeatureFile/get-action-word').then(res => res.data);
};

export const breakPointFeatureFileAPI = (id: number, isBreak: boolean) => {
  return client
    .get(`/AcceptanceCriteriaFeatureFile/break-step?stepInstanceId=${id}&isBreak=${isBreak}`)
    .then(res => res.data);
};

export const createFeatureFileAPI = (id: number) => {
  return client
    .post(`/AcceptanceCriteriaFeatureFile/create-default-feature-file?acceptanceCriteriaId=${id}`)
    .then(res => res.data);
};

export const deleteFeatureFileAPI = (param: any) => {
  return client.delete('/AcceptanceCriteriaFeatureFile/delete-steps', { data: param }).then(res => res.data);
};

export const insertStepFeatureFileAPI = params => {
  return client.post('/AcceptanceCriteriaFeatureFile/insert-steps', params);
};

export const undoStepFeatureFileAPI = params => {
  return client.post('/AcceptanceCriteriaFeatureFile/undo-last-change', params);
};

export const pasteStepFeatureFileAPI = params => {
  return client.post('/AcceptanceCriteriaFeatureFile/paste-steps', params);
};

export const createUpdateFeatureFileStepAPI = params => {
  return client.post('/AcceptanceCriteriaFeatureFile/create-update-step', params).then(res => res.data);
};
// end feature file
