import { createSlice } from '@reduxjs/toolkit';

export interface SettingState {
  isLoading: boolean;
  activeTab: string;
}

const initialState: SettingState = {
  isLoading: false,
  activeTab: '1',
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setActiveTab } = settingSlice.actions;
export default settingSlice.reducer;
