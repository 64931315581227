import client from '.';

export const getListPermissionAPI = () => {
  return client.get('/Admins/get-permissions');
};

export const getPermissionDetailAPI = params => {
  return client.get('/Admins/get-permission-detail', { params: params });
};

export const updatePermissionAPI = params => {
  return client.post('/Admins/update-permission', params);
};

export const getListRoleAPI = () => {
  return client.get('/Admins/get-roles');
};

export const getRoleDetailAPI = params => {
  return client.get('/Admins/get-role-detail', { params: params });
};

export const createRoleAPI = params => {
  return client.post('/Admins/create-role', params);
};

export const updateRoleAPI = params => {
  return client.post('/Admins/update-role', params);
};

export const getWebModuleAPI = () => {
  return client.get('/Admins/get-web-modules');
};

export const getUserRolesAPI = (params?: any) => {
  return client.get('/Admins/get-user-roles', { params });
};

export const getUserRoleDetailAPI = (userId: string) => {
  return client.get('/Admins/get-user-role-detail', { params: { userId } });
};

export const updateUserRoleAPI = payload => {
  return client.post('/Admins/update-user-role', payload);
};

export const getUserAdditionalPermissionAPI = (userId: string) => {
  return client.get('/Admins/get-user-additional-permission', { params: { userId } });
};

export const updateUserAdditionalPermissionAPI = payload => {
  return client.post('/Admins/update-user-additional-permission', payload);
};

export const getUserModulePermissionAPI = () => {
  return client.get('/Users/get-user-module-permission');
};
