import { createSlice } from '@reduxjs/toolkit';

export interface TestDesignState {
  isLoading: boolean;
  isUpdateRequirement: boolean;
  requirementSelectedId: number;
  isLoadingTestDesign: boolean;
  isLoadingListRequirement: boolean;
  isAssignToMeTestDesign: boolean;
  isAssignToMeTestDesignAcceptance: boolean;
  isLoadingTestDesignAcceptance: boolean;
  isLoadingRightChart: boolean;
  isLoadingLeftChart: boolean;
  isSliderInAcceptance: boolean;
}

const initialState: TestDesignState = {
  isLoading: false,
  isUpdateRequirement: false,
  requirementSelectedId: 0,
  isLoadingTestDesign: false,
  isLoadingListRequirement: false,
  isAssignToMeTestDesign: true,
  isAssignToMeTestDesignAcceptance: true,
  isLoadingTestDesignAcceptance: false,
  isLoadingRightChart: false,
  isLoadingLeftChart: false,
  isSliderInAcceptance: true,
};

export const testDesignSlice = createSlice({
  name: 'test-design',
  initialState,
  reducers: {
    setRequirementTestDesignSelectedId: (state, action) => {
      state.requirementSelectedId = action.payload;
    },
    setIsLoading: state => {
      state.isLoading = !state.isLoading;
    },
    setAssignToMeTestDesign: (state, action) => {
      state.isAssignToMeTestDesign = action.payload;
    },
    setAssignToMeTestDesignAcceptance: (state, action) => {
      state.isAssignToMeTestDesignAcceptance = action.payload;
    },
    triggerLoadingTestDesign: state => {
      state.isLoadingTestDesign = !state.isLoadingTestDesign;
    },
    triggerLoadingListRequirement: state => {
      state.isLoadingListRequirement = !state.isLoadingListRequirement;
    },
    triggerIsLoadingTestDesignAcceptance: state => {
      state.isLoadingTestDesignAcceptance = !state.isLoadingTestDesignAcceptance;
    },
    triggerIsLoadingRightChart: state => {
      state.isLoadingRightChart = !state.isLoadingRightChart;
    },
    triggerIsLoadingLeftChart: state => {
      state.isLoadingLeftChart = !state.isLoadingLeftChart;
    },
    setSliderInAcceptance: (state, action) => {
      state.isSliderInAcceptance = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const {
  setRequirementTestDesignSelectedId,
  setIsLoading,
  triggerLoadingTestDesign,
  triggerLoadingListRequirement,
  setAssignToMeTestDesign,
  setAssignToMeTestDesignAcceptance,
  triggerIsLoadingTestDesignAcceptance,
  triggerIsLoadingRightChart,
  triggerIsLoadingLeftChart,
  setSliderInAcceptance,
} = testDesignSlice.actions;

export default testDesignSlice.reducer;
