import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISelectUser } from 'app/models/userStories.model';
import { IPermissionResult } from '../Setting/RolesAndPermission/Setting.type';

export interface AuthState {
  user?: any;
  accessToken?: string;
  listUser: ISelectUser[];
  userPermissions: string[];
  webModulePermission: IPermissionResult[];
}

const initialState: AuthState = {
  user: null,
  accessToken: '',
  listUser: [],
  userPermissions: [],
  webModulePermission: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    setListUser: (state, action) => {
      state.listUser = action.payload;
    },
    setUserPermissions: (state, action) => {
      let authToken = action.payload;
      if (authToken) {
        var base64Url = authToken.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
        );
        let jsonData = JSON.parse(jsonPayload);
        state.userPermissions = jsonData?.Permissions ?? [];
        state.webModulePermission = jsonData?.WebModulePermission ? JSON.parse(jsonData?.WebModulePermission) : [];
      }
    },
    setUserPermissionWhenReloadPage: (state, action) => {
      state.webModulePermission = action.payload;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, setListUser, setUserPermissions, setUserPermissionWhenReloadPage } = authSlice.actions;

export default authSlice.reducer;
