import { createSlice } from '@reduxjs/toolkit';
import { IListLicenceType } from './LicenceManagement.type';

export interface LicenceManagementState {
  isLoading: boolean;
  isLoadingLicencedRequests: boolean;
  listLicenceType: IListLicenceType[];
}

const initialState: LicenceManagementState = {
  isLoading: false,
  isLoadingLicencedRequests: false,
  listLicenceType: [],
};

export const licenceManagementSlice = createSlice({
  name: 'LicenceManagement',
  initialState,
  reducers: {
    setLicenceType: (state, action) => {
      state.listLicenceType = action.payload;
    },
    triggerLoadingLicencedRequests: state => {
      state.isLoadingLicencedRequests = !state.isLoadingLicencedRequests;
    },
  },
  extraReducers: builder => {},
});

export const { setLicenceType, triggerLoadingLicencedRequests } = licenceManagementSlice.actions;
export default licenceManagementSlice.reducer;
