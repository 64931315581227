import { createSlice } from '@reduxjs/toolkit';
import { IDocumentConvert } from './DocumentConvert.type';

export interface DocumentConvertState {
  isLoading: boolean;
  isReloadListDocument: boolean;
  documentSelected: IDocumentConvert | null;
  documentDetail: any;
}

const initialState: DocumentConvertState = {
  isLoading: false,
  isReloadListDocument: false,
  documentSelected: null,
  documentDetail: null,
};

const documentConvertSlice = createSlice({
  name: 'document-convert',
  initialState,
  reducers: {
    setDocumentSelected: (state, action) => {
      state.documentSelected = action.payload;
    },
    setDocumentDetail: (state, action) => {
      state.documentDetail = action.payload;
    },

    triggerReloadListDocument: state => {
      state.isReloadListDocument = !state.isReloadListDocument;
    },
  },
  extraReducers: builder => {},
});

export const { triggerReloadListDocument, setDocumentSelected, setDocumentDetail } = documentConvertSlice.actions;

export default documentConvertSlice.reducer;
