import {} from 'react-router-dom';

import { ModuleItem } from 'app/constant';
import { useAppSelector } from 'app/store';
import { ActionType, ModuleName } from 'app/models/permission.model';

export const convertPermissions = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  let jsonData = JSON.parse(jsonPayload);
  return jsonData?.WebModulePermission ? JSON.parse(jsonData?.WebModulePermission) : [];
};

export const getPermissionsWhenChangeProject = (token: string, history: any) => {
  const convertedPermissions = convertPermissions(token);
  const hasHomePermission = convertedPermissions?.find(
    item => item?.Module === ModuleName.Worklist && item?.Permissions?.includes(ActionType.VIEW),
  );
  const firstAccessPage =
    hasHomePermission ?? convertedPermissions?.find(item => item.Permissions.includes(ActionType.VIEW));
  const firstAccessPageUrl = ModuleItem[firstAccessPage?.Module]?.path;

  if (firstAccessPageUrl) {
    history.push(firstAccessPageUrl, { checked: true });
  } else {
    history.push('/document-management');
  }
};

export const usePermission = () => {
  const webModulePermission = useAppSelector(state => state.auth.webModulePermission);
  const isHavePermission = (moduleName, actionType) => {
    let module = webModulePermission?.find(item => item?.module === moduleName);
    if (!module) return false;
    if (!module.permissions?.includes(actionType)) return false;
    return true;
  };
  return {
    isHavePermission,
  };
};
